<template>
  <section class="h-100">

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_orange_logo_horizontal.png"
          style="max-height: 60px;"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase">Pre-register</span>
        <img
          src="@/assets/images/menu.svg"
          style="display: inline-block;margin-left: 20px;"
        >
      </div>
    </div>

    <div class="flex h-screen text-center">

      <div class="m-auto pb-10">

        <div class="mb-12 relative">
          <router-link
            :to="{name: 'registration.v2.welcome'}"
            class="absolute left-0"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left.svg"
              class="inline-block"
            > Back
          </router-link>

          <span>Approximately 5 min. to complete</span>
        </div>

        <div class="mt-9">
          <h3 class="text text-center text-neutral mb-9">
            You look really familiar
          </h3>

          <h3 class="text text-center text-black mb-10">
            Are any of these you?
          </h3>

          <p class="m-auto mt-5 max-w-sm">
            <a href="#" class="text-primary font-bold">How did you get this information?</a>
          </p>
        </div>

        <div class="grid grid-cols-3 gap-16 mt-10">
          <div>
            <img
              src="@/assets/images/jon_smith_1.png"
              class="inline-block"
            >
            <h2 class="pb-2 mb-2" style="font-size: 26px;border-bottom: 0.5px solid #332C54;">Jon Smith</h2>

            <span class="block">Product Design Manager</span>
            <span class="block text-primary">Panera Bread Co.</span>
          </div>

          <div>
            <img
              src="@/assets/images/jon_smith_2.png"
              class="inline-block"
            >
            <h2 class="pb-2 mb-2" style="font-size: 26px;border-bottom: 0.5px solid #332C54;">Jon Smith</h2>

            <span class="block">Product Designer</span>
            <span class="block text-primary">Haneke Design</span>
          </div>

          <div>
            <img
              src="@/assets/images/jon_smith_3.png"
              class="inline-block"
            >
            <h2 class="pb-2 mb-2" style="font-size: 26px;border-bottom: 0.5px solid #332C54;">Jon Smith</h2>

            <span class="block">Design Director</span>
            <span class="block text-primary">Prism Design Group</span>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-16 mt-10">
          <div>
            <img
              src="@/assets/images/jon_smith_4.png"
              class="inline-block"
            >
            <h2 class="pb-2 mb-2" style="font-size: 26px;border-bottom: 0.5px solid #332C54;">Jon Smith</h2>

            <span class="block">Director, Design</span>
            <span class="block text-primary">Thirteen05 Creative</span>
          </div>

          <div>
            <img
              src="@/assets/images/jon_smith_5.png"
              class="inline-block"
            >
            <h2 class="pb-2 mb-2" style="font-size: 26px;border-bottom: 0.5px solid #332C54;">Jon Smith</h2>

            <span class="block">Senior Data Engineer</span>
            <span class="block text-primary">Airbnb</span>
          </div>

          <div>
            <img
              src="@/assets/images/jon_smith_6.png"
              class="inline-block"
            >
            <h2 class="pb-2 mb-2" style="font-size: 26px;border-bottom: 0.5px solid #332C54;">Jon Smith</h2>

            <span class="block">Senior Product Designer</span>
            <span class="block text-primary">J2 Studio</span>
          </div>
        </div>

        <div class="mt-10 pt-10 text-center">
          <router-link
            :to="{name: 'registration.v2.signup.password'}"
            class="btn btn-primary-outline inline-block w-3/5"
          >
            None of these is me
          </router-link>
        </div>

        <div class="mt-10 text-center">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot active"></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SetupProfile'
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: bold;
}
h3 > strong {
  font-weight: bold;
}
</style>
